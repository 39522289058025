import PromoImages from "./PromoImages";
import img1 from "../assets/images/promos/vday/promo (1).jpg";
import img2 from "../assets/images/promos/vday/promo (2).jpg";
import img3 from "../assets/images/promos/vday/promo (3).jpg";
import img4 from "../assets/images/promos/vday/promo (4).jpg";
import img5 from "../assets/images/promos/vday/promo (5).jpg";
import img6 from "../assets/images/promos/vday/promo (6).jpg";
import img7 from "../assets/images/promos/vday/promo (7).jpg";
import img8 from "../assets/images/promos/vday/promo (8).jpg";
import img9 from "../assets/images/promos/vday/promo (9).jpg";
import img10 from "../assets/images/promos/vday/promo (10).jpg";
import img11 from "../assets/images/promos/vday/promo (11).jpg";
import img12 from "../assets/images/promos/vday/promo (12).jpg";
import flyer from "../assets/images/promos/vday/flyer.jpg";

export default function Vday() {
  const images = [
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
    img9,
    img10,
    img11,
    img12,
  ];
  const name = "vday";

  return (
    <>
      <div className="row justify-content-center text-center mt-5 mb-5">
        <img src={flyer} alt="promo flyer" style={{ maxWidth: "800px" }} />
      </div>
      <PromoImages images={images} name={name} />
    </>
  );
}
