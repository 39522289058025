import { Helmet } from "react-helmet";
import shopInfo from "../components/shopInfo";
import TradFest from "../components/TradFest";
import MemorialDay from "../components/MemorialDay";
import Promo420 from "../components/Promo420";
import Pokemon from "../components/Pokemon";
import Friday13 from "../components/Friday13";
import Vday from "../components/Vday";

function PromoPage() {
  return (
    <>
      <Helmet>
        <link rel="stylesheet" href="promo.css" />
        <title>{shopInfo.title} | Flash Specials</title>
      </Helmet>
      <div className="container-lg mt-60 mb-60">
        <Vday />
        <hr className="border opacity-25" />
        <TradFest />
        <hr className="border opacity-25" />
        <Friday13 />
        <hr className="border opacity-25" />
        <Pokemon />
        <hr className="border opacity-25" />
        <MemorialDay />
        <hr className="border opacity-25" />
        <Promo420 />
      </div>
    </>
  );
}

export default PromoPage;
